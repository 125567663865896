import axios from 'axios';
import store from '@/store';

const AXIOS_CONFIG = {
  baseURL: process.env.VUE_APP_ROUTES_API,
  headers: {
    'Content-Type': 'application/json',
  },
};

const httpClient = axios.create(AXIOS_CONFIG);

httpClient.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`;
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
);

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const config = error.config;
    console.error(`[${config.method.toUpperCase()}] ${config.url} error: ${error}`);
    return Promise.reject(error);
  },
);


const getEndpoints = () => httpClient.get('');

const createSharedEndpoint = (protocol: string, network: string, api: string, addon: string, namespace: string, recaptchaToken: string) => {
  return httpClient.post(
    '',
    { protocol, network, api, addon, namespace },
    {
      headers: {
       'recaptcha-token': recaptchaToken,
      },
    },
  );
};

const rollSharedEndpoint = (id: string, recaptchaToken: string) =>
  httpClient.put(`${id}/`, {}, {
    headers: {
      'recaptcha-token': recaptchaToken,
    },
  });

const renameSharedEndpoint = (id: string, name: string, recaptchaToken: string) =>
  httpClient.put('', {
    id,
    name,
  }, {
    headers: {
      'recaptcha-token': recaptchaToken,
    },
  });

const deleteSharedEndpoint = (id: string, recaptchaToken: string) => {
  return httpClient.delete(
    `${id}/`,
    {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    },
  );
};

const getCodeSnippets = (namespaces: ('shared' | 'dedicated')[] = ['shared'], recaptchaToken: string, protocol?: string) =>
  protocol === 'all'
    ?
    httpClient.post('config/', { namespaces }, {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    })
    :
    httpClient.post('config/', { namespaces, protocol }, {
      headers: {
        'recaptcha-token': recaptchaToken,
      },
    });


export {
  getEndpoints,
  createSharedEndpoint,
  rollSharedEndpoint,
  deleteSharedEndpoint,
  getCodeSnippets,
  renameSharedEndpoint,
};
