import axios from 'axios';
import store from '@/store';
import axiosRetry from 'axios-retry';

const AXIOS_CONFIG = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const AXIOS_CONFIG_V6 = {
  baseURL: process.env.VUE_APP_API_URL_V6,
  headers: {
    'Content-Type': 'application/json',
  },
};

const httpClient = axios.create(AXIOS_CONFIG);
const httpClientV6 = axios.create(AXIOS_CONFIG_V6);

axiosRetry(httpClient, { retries: 3 });

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const config: { method: string; url: string } = error.config;
    console.error(`[${config.method.toUpperCase()}] ${config.url} error: ${error}`);
    return Promise.reject(error);
  },
);

const getUserBalance = () =>
  httpClientV6.get('/plan/', {
    headers: {
      Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
    },
  });

const getUserPurchases = () =>
  httpClient.get('/orders/orders/', {
    headers: {
      Authorization: `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`,
    },
});

const getUserData = () =>
  httpClient.get('/account/', {
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  });

const getUserReferralInfo = () =>
  httpClient.get('/referral-program/participant-info', {
    headers: {
      Authorization: `Bearer ${store.state.accessToken}`,
    },
  });

const acceptUserToReferralProgram = () =>
  httpClient.put(
    '/referral-program/participant-info',
    {},
    {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
      },
    },
  );

const sendPayoutCode = (user_id: string, email: string, checkout_sum: number, wallet_address: string) =>
  httpClient.post(
    '/referral-program/validate',
    {
      user_id,
      email,
      checkout_sum,
      wallet_address,
    },
    {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
      },
    },
  );

const resendPayoutCode = (checkout_id: string) =>
  httpClient.put(
    '/referral-program/validate',
    {
      checkout_id,
    },
    {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
      },
    },
  );

const createPayout = (checkout: string, code: string, user_id: string) =>
  httpClient.post(
    '/referral-program/checkout',
    {
      checkout,
      code,
      user_id,
    },
    {
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
      },
    },
  );

const changeUserData = (name: string, email: string, recaptchaToken: string) =>
  httpClient.put(
    '/account/',
    {
      name,
      email,
    },
    {
      'axios-retry': {
        retries: 0,
      },
      headers: {
        Authorization: `Bearer ${store.state.accessToken}`,
        'recaptcha-token': recaptchaToken,
      },
    },
  );

export {
  createPayout,
  sendPayoutCode,
  resendPayoutCode,
  getUserPurchases,
  getUserData,
  getUserBalance,
  getUserReferralInfo,
  changeUserData,
  acceptUserToReferralProgram,
};
