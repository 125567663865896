import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import {
  SharedNodesActions,
  SharedNodesGetters,
  SharedNodesMutations,
  SharedNodesState,
} from '@/types/dashboard/sharedNodes/store';
import {
  createSharedEndpoint,
  deleteSharedEndpoint,
  rollSharedEndpoint,
  getCodeSnippets,
  getEndpoints, renameSharedEndpoint,
} from '@/api/sharedNodes';
import { MappedEndpoint } from '@/types/dashboard/sharedNodes/endpoint';
import { useNotification } from '@/composables/useNotification';
import { Node } from '@/types/shared/shared';

export type State = SharedNodesState;
export type Mutations = SharedNodesMutations;
export type Getters = SharedNodesGetters;
export type Actions = SharedNodesActions;

const state: () => State = () => ({
  endpoints: [],
  codeSnippets: [],
  codeSnippetsSpecs: {
    protocol: '',
    namespace: 'shared',
  },
  currentNode: {
    protocol: '',
  },
  showTopUpBalanceModal: false,
  showContactBoostRPSModal: false,
  showUpgradePlanModal: false,
});
const getters: GetterTree<State, RootState> & Getters = {
  groupOfSharedEndpoints(state, getters, rootState) {
    const protocols = Array.from(new Set(state.endpoints.map((endpoint) => endpoint.protocol)));

    const getMappedEndpointsByProtocol = (protocol: string) => {
      const mappedEndpoints: { [key: string]: MappedEndpoint[] } = {};
      Array.from(new Set(state.endpoints.filter((endpoint) => endpoint.protocol === protocol)
        .map((endpoint) => endpoint.network)))
        .forEach((network) => {
          mappedEndpoints[network] = state.endpoints.filter((endpoint) => endpoint.network === network && endpoint.protocol === protocol)
            .map((endpoint) => ({
              ...endpoint,
              network: {
                value: endpoint.network,
                // @ts-ignore
                text: getters.nodes.find((node) => node.protocol._key === endpoint.protocol)?.networks[endpoint.network]?.name,
              },
              api: {
                value: endpoint.api,
                // @ts-ignore
                text: getters.nodes.find((node) => node.protocol._key === endpoint.protocol)?.networks[endpoint.network]?.apis[endpoint.api]?.name,
              },
              addon: {
                value: endpoint.addon,
                // @ts-ignore
                text: getters.nodes.find((node) => node.protocol._key === endpoint.protocol)?.networks[endpoint.network]?.service_levels.find((serviceLevel) => serviceLevel._key === 'shared')?.addons.find((addon) => addon._key === endpoint.addon)?.name,
              },
              statisticsPreview:
                rootState.statistics.statisticsPreview['shared'][endpoint._key]
                  ?
                  rootState.statistics.statisticsPreview['shared'][endpoint._key]
                  :
                  [0, 0, 0, 0, 0],
            }));
        });
      return mappedEndpoints;
    };
    const groups = protocols.map((ticker) => {
      const endpoints = getMappedEndpointsByProtocol(ticker);
      // @ts-ignore
      const lastUpdate = Object.values(endpoints).flat(Infinity).sort((endpointA, endpointB) => new Date(endpointB.update_at) - new Date(endpointA.update_at))[0].update_at;
      return ({
        protocol: {
          // @ts-ignore
          value: getters.nodes.find((node) => node.protocol._key === ticker)?.protocol?.name,
          imgPath: `coins/${ticker}`,
          ticker,
        },
        endpoints,
        lastUpdate,
      });
    });
    return groups.sort((groupA, groupB) => new Date(groupB.lastUpdate).getTime() - new Date(groupA.lastUpdate).getTime());
  },
};
const mutations: MutationTree<State> & Mutations = {
  setEndpoints(state, endpoints) {
    state.endpoints = endpoints;
  },
  addSharedEndpoint(state, endpoint) {
    state.endpoints.unshift(endpoint);
  },
  deleteSharedEndpoint(state, id) {
    state.endpoints = state.endpoints.filter((endpoint) => endpoint._key !== id);
  },
  replaceSharedEndpoint(state, { id, newEndpoint }) {
    state.endpoints = state.endpoints.map((endpoint) => endpoint._key === id ? newEndpoint : endpoint);
  },
  renameSharedEndpoint(state, { id, name }) {
    state.endpoints = state.endpoints.map((endpoint) => endpoint._key === id ? {
      ...endpoint,
      name,
    } : endpoint);
  },
  setCurrentNodeShared(state, node) {
    state.currentNode = node;
  },
  setCodeSnippets(state, codeSnippets) {
    state.codeSnippets = codeSnippets;
  },
  setCodeSnippetsSpecs(state, specs) {
    state.codeSnippetsSpecs = specs;
  },
  setShowTopUpBalanceModal(state, show) {
    state.showTopUpBalanceModal = show;
  },
  setShowContactBoostRPSModal(state, show) {
    state.showContactBoostRPSModal = show;
  },
  setShowUpgradePlanModal(state, show) {
    state.showUpgradePlanModal = show;
  },
};

const actions: ActionTree<State, RootState> & Actions = {
  async getEndpoints({ commit }) {
    try {
      const endpoints = await getEndpoints();
      // @ts-ignore
      commit('setEndpoints', endpoints.data.filter((endpoint) => endpoint.namespace === 'shared'));
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('endpoints_get-endpoints'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async createSharedEndpoint({ commit }, { protocol, network, api, addon, namespace, recaptchaToken }) {
    try {
      const endpoint = await createSharedEndpoint(protocol, network, api, addon, namespace, recaptchaToken);
      commit('addSharedEndpoint', endpoint.data);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('endpoints_create-endpoint'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async deleteSharedEndpoint({ commit }, { id, recaptchaToken }) {
    try {
      await deleteSharedEndpoint(id, recaptchaToken);
      commit('deleteSharedEndpoint', id);
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('endpoints_delete-endpoint'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async rollSharedEndpoint({ commit }, { id, recaptchaToken }) {
    try {
      const endpoint = await rollSharedEndpoint(id, recaptchaToken);
      commit('replaceSharedEndpoint', { id, newEndpoint: endpoint.data });
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('endpoints_roll-endpoint'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async renameSharedEndpoint({ commit }, { id, name, recaptchaToken }) {
    try {
      await renameSharedEndpoint(id, name, recaptchaToken);
      commit('renameSharedEndpoint', { id, name });
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('endpoints_rename-endpoint'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async getCodeSnippets({ commit, getters }, { namespaces, protocol, recaptchaToken }) {
    try {
      const codeSnippets = await getCodeSnippets(namespaces, recaptchaToken, protocol);
      commit('setCodeSnippets', Object.keys(codeSnippets.data).map((language) => ({
        language,
        code: codeSnippets.data[language],
      })));

      commit('setCodeSnippetsSpecs', {
        namespace: namespaces.length === 1 ? namespaces[0] : 'all',
        protocol: getters.nodes.find((node: Node) => node.protocol._key === protocol)?.protocol?.name || protocol,
      });
    } catch (e) {
      // TODO: add sharedNodes error
      // const { errorData } = useNotification();
      // commit('setNotificationMessage', { ...errorData('dedicated-order_get-available-protocols'), type: 'error' });
      // commit('setNotificationMessageDisplay', true);
    }
  },
};

const sharedNodes: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default sharedNodes;


