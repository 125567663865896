import axios from 'axios';
import store from '@/store';
import { Periods } from '@/types/dedicatedNodeConfigurator/steps/planStep';
import { Modes, Networks } from '@/types/dedicatedNodeConfigurator/steps/configStep';
import { PaymentMethod } from '@/types/shared/shared';

const AXIOS_CONFIG = {
  baseURL: process.env.VUE_APP_NODE_ORDER_API,
  headers: {
    'Content-Type': 'application/json',
  },
};

const AXIOS_ORDERS_CONFIG = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const httpClient = axios.create(AXIOS_CONFIG);

httpClient.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`;
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
);

const httpClientOrders = axios.create(AXIOS_ORDERS_CONFIG);

httpClientOrders.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${store.getters.isPersonalAccount ? store.state.accessToken : store.state.teams.teamToken}`;
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
);

const getPriceAndEstimate = (protocol: string, network: string, mode: string, client: string, period: Periods, apis: string[], addons: string[]) =>
  httpClient.post(
    'estimate',
    {
      protocol,
      network,
      mode,
      client,
      period,
      apis,
      addons,
      service_level: 'dedicated',
    },
  );

const getDedicatedNodes = () => httpClientOrders.get('/dedicated-nodes/');

const orderDedicatedNode = (mode: Modes, network: Networks, protocol: string, client: string, period: Periods, price: number, signature: string, apis: string[], addons: string[], payment_method: PaymentMethod | undefined, isSubscription: boolean, recaptchaToken: string) =>
  httpClientOrders.post(
    '/orders/create-dedicated-order/',
    {
      mode,
      network,
      protocol,
      client,
      period,
      price,
      apis,
      addons,
      payment_method,
      is_subscription:  isSubscription,
    },
    {
      headers: {
        'x-configuration-signature': signature,
        'recaptcha-token': recaptchaToken,
      },
    },
  );

const prolongateDedicatedNode = (dedicatedNodeId: string, period: Periods, price: number, signature: string, recaptchaToken: string, paymentMethod: PaymentMethod, isSubscription: boolean) =>
  httpClientOrders.post(
    '/orders/create-dedicated-prolongation-order/',
    {
      dedicated_node_id: dedicatedNodeId,
      period,
      price,
      payment_method: paymentMethod,
      is_subscription:  isSubscription,
    },
    {
      headers: {
        'x-configuration-signature': signature,
        'recaptcha-token': recaptchaToken,
      },
    },
  );

  const buyPackage = (data: { "pack_id": string,  "period": string, "payment_method": PaymentMethod } | {} = {}, recaptchaToken: string) =>
  httpClientOrders.post(
    `/orders/create-shared-order/`,
    data,
    {
      headers: {
       'recaptcha-token': recaptchaToken,
      },
    },
  );

export {
  getPriceAndEstimate,
  orderDedicatedNode,
  prolongateDedicatedNode,
  getDedicatedNodes,
  buyPackage,
};
