import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import {
  DedicatedNodesActions,
  DedicatedNodesGetters,
  DedicatedNodesMutations,
  DedicatedNodesState,
} from '@/types/dashboard/dedicatedNodes/store';
import { RootState } from '@/types/store';
import { getDedicatedNodes } from '@/api/nodeOrder';
import { useNotification } from '@/composables/useNotification';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { MappedEndpoint } from '@/types/dashboard/sharedNodes/endpoint';
import { DedicatedNode } from '@/types/dashboard/dedicatedNodes/dedicatedNode';
import {
  deleteDedicatedEndpoint,
  renameDedicatedEndpoint,
  rollDedicatedEndpoint,
  updateDedicatedNode,
} from '@/api/dedicatedNodes';

export type State = DedicatedNodesState;
export type Mutations = DedicatedNodesMutations;
export type Getters = DedicatedNodesGetters;
export type Actions = DedicatedNodesActions;

const state: () => State = () => ({
  dedicatedNodes: [],
  currentDedicatedNode: null,
  showDedicatedNodeProlongationModal: {
    showModal: false,
    showDedicatedNodesSelectionStep: false,
  },
  showAddNewAPIModal: false,
  currentNode: {
    protocol: '',
    expire_at_days: 0,
    network: '',
    dedicatedNodeId: '',
  },
  showTwoDaysTrialModal: {
    initialScreenModal: false,
    requirementsModal: false,
    contactModal: false,
    successScreenModal: false,
  },
});

const getters: GetterTree<State, RootState> & Getters = {
  groupOfDedicatedEndpoints(state, getters, rootState) {
    const getMappedEndpoints = (protocol: string, accessTokens: DedicatedNode['access_tokens'], updateAt: string) => {
      const mappedEndpoints: { [key: string]: MappedEndpoint[] } = {};
      Array.from(new Set(Object.values(accessTokens || {}).map(({ network }) => network))).forEach((network) => {
        mappedEndpoints[network] = Object.values(accessTokens).filter((accessToken) => accessToken.network === network)
          .map((accessToken) => ({
            name: accessToken.name,
            _id: `access_tokens/${accessToken._key}`,
            _key: accessToken._key,
            update_at: updateAt,
            namespace: 'dedicated',
            protocol,
            network: {
              value: accessToken.network,
              // @ts-ignore
              text: getters.nodes.find((node) => node.protocol._key === protocol)?.networks[accessToken.network]?.name,
            },
            api: {
              value: accessToken.api,
              // @ts-ignore
              text: getters.nodes.find((node) => node.protocol._key === protocol)?.networks[accessToken.network]?.apis[accessToken.api]?.name,
            },
            addon: {
              value: accessToken.addon,
              // @ts-ignore
              text: getters.nodes.find((node) => node.protocol._key === protocol)?.networks[accessToken.network]?.service_levels.find((serviceLevel) => serviceLevel._key === 'dedicated')?.addons.find((addon) => addon._key === accessToken.addon)?.name,
            },
            statisticsPreview:
              rootState.statistics.statisticsPreview['dedicated'][accessToken._key]
                ?
                rootState.statistics.statisticsPreview['dedicated'][accessToken._key]
                :
                [0, 0, 0, 0, 0],
          }));
      });
      return mappedEndpoints;
    };


    const groups = state.dedicatedNodes.map((dedicatedNode) => {
      const endpoints = getMappedEndpoints(dedicatedNode.protocol, dedicatedNode.access_tokens, dedicatedNode.update_at);
      // @ts-ignore
      const lastUpdate = Object.values(endpoints).flat(Infinity).sort((endpointA, endpointB) => new Date(endpointB.update_at) - new Date(endpointA.update_at))[0]?.update_at;
      return ({
        protocol: {
          // @ts-ignore
          value: getters.nodes.find((node) => node.protocol._key === dedicatedNode.protocol)?.protocol?.name,
          imgPath: `coins/${dedicatedNode.protocol}`,
          ticker: dedicatedNode.protocol,
        },
        endpoints: endpoints,
        dedicatedNodeId: dedicatedNode._id,
        expireAt: dedicatedNode.expire_at,
        lastUpdate,
        mode: dedicatedNode.sync_mode,
      });
    });

    return groups.sort((groupA, groupB) => new Date(groupB.lastUpdate).getTime() - new Date(groupA.lastUpdate).getTime());
  },
  expiresSoonDedicatedNodes(state, getters) {
    return state.dedicatedNodes
      .filter((dedicatedNode) => new Date(dedicatedNode.expire_at).getTime() < Date.now() + 7 * 24 * 60 * 1000)
      // @ts-ignore
      .sort((dedicatedNodeA, dedicatedNodeB) => new Date(dedicatedNodeA.expire_at) - new Date(dedicatedNodeB.expire_at))
      .map((dedicatedNode) => ({
        ...dedicatedNode,
        protocol: {
          ticker: dedicatedNode.protocol,
          // @ts-ignore
          value: getters.nodes.find((node) => node.protocol._key === dedicatedNode.protocol)?.protocol?.name || dedicatedNode.protocol,
        },
        expire_at_days: Math.round((new Date(dedicatedNode.expire_at).getTime() - Date.now()) / (1000 * 3600 * 24)),
      }));
  },
};

const mutations: MutationTree<State> & Mutations = {
  setDedicatedNodes(state, dedicatedNodes) {
    state.dedicatedNodes = dedicatedNodes;
  },
  updateDedicatedNodeAPIS(state, { id, accessToken }) {
    state.dedicatedNodes = state.dedicatedNodes.map((dn) => dn._id === id ? {
      ...dn,
      access_tokens: { ...dn.access_tokens, [accessToken._key]: accessToken },
    } : dn);
  },
  renameDedicatedEndpoint(state, { dedicatedNodeId, accessTokenId, name }) {
    state.dedicatedNodes = state.dedicatedNodes.map((dn) => dn._id === dedicatedNodeId ? {
      ...dn,
      access_tokens: {
        ...dn.access_tokens,
        [`${accessTokenId}`]: { ...dn.access_tokens[`${accessTokenId}`], name },
      },
    } : dn);
  },
  addDedicatedNode(state, dedicatedNode) {
    state.dedicatedNodes.unshift(dedicatedNode);
  },
  deleteDedicatedEndpoint(state, { dedicatedNodeId, id }) {
    state.dedicatedNodes = state.dedicatedNodes.map((dn) => {
      if (dn._id === dedicatedNodeId) {

        return {
          ...dn,
          access_tokens:
            Object.keys(dn.access_tokens).filter((accessToken) => dn.access_tokens[accessToken]._key !== id).reduce((acc, item) => ({
              ...acc,
              [item]: dn.access_tokens[item],
            }), {}),
        };
      }
      return dn;
    });
  },
  replaceDedicatedEndpoint(state, { dedicatedNodeId, id, newEndpoint }) {
    state.dedicatedNodes = state.dedicatedNodes.map((dn) => {
      if (dn._id === dedicatedNodeId) {
        return {
          ...dn,
          access_tokens:
            Object.keys(dn.access_tokens).reduce((acc, item) => ({
              ...acc,
              [dn.access_tokens[item]._key === id ? newEndpoint._key : item]: dn.access_tokens[item]._key === id ? newEndpoint : dn.access_tokens[item],
            }), {}),
        };
      }
      return dn;
    });
  },
  setCurrentDedicatedNode(state, { dedicatedNodeId, protocol, expire_at_days }) {
    // @ts-ignore
    state.currentDedicatedNode = state.dedicatedNodes.find((dedicatedNode) => dedicatedNode._id === dedicatedNodeId) ? {
      ...state.dedicatedNodes.find((dedicatedNode) => dedicatedNode._id === dedicatedNodeId),
      protocol,
      expire_at_days,
    } : null;
  },
  setCurrentNodeDedicated(state, node) {
    state.currentNode = node;
  },
  setShowDedicatedNodeProlongationModal(state, show) {
    state.showDedicatedNodeProlongationModal = show;
  },
  setShowAddNewAPIModal(state, show) {
    state.showAddNewAPIModal = show;
  },
  setShowTwoDaysTrialModal(state, show) {
    state.showTwoDaysTrialModal = { ...state.showTwoDaysTrialModal, ...show };
  },
};

const actions: ActionTree<State, RootState> & Actions = {
  async getDedicatedNodes({ commit }) {
    try {
      const res = await getDedicatedNodes();
      // @ts-ignore
      commit('setDedicatedNodes', res.data.filter((dn) => dn.status === 'active' || dn.status === 'confirmed'));

    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('dedicated-order_get-dedicated-nodes'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async updateDedicatedNodeAPIS({ commit }, { id, data, recaptchaToken }) {
    try {
      const res = await updateDedicatedNode(id, data, recaptchaToken);
      commit('updateDedicatedNodeAPIS', { id, accessToken: res.data });
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('endpoints_create-endpoint'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async renameDedicatedEndpoint({ commit }, { accessTokenId, name, dedicatedNodeId, recaptchaToken }) {
    try {
      await renameDedicatedEndpoint(accessTokenId, name, recaptchaToken);
      commit('renameDedicatedEndpoint', { accessTokenId, dedicatedNodeId, name });
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('endpoints_rename-endpoint'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async deleteDedicatedEndpoint({ commit }, { dedicatedNodeId, id, recaptchaToken }) {
    try {
      await deleteDedicatedEndpoint(id, recaptchaToken);
      commit('deleteDedicatedEndpoint', { id, dedicatedNodeId });
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('endpoints_delete-endpoint'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async rollDedicatedEndpoint({ commit }, { dedicatedNodeId, id, recaptchaToken }) {
    try {
      const endpoint = await rollDedicatedEndpoint(id, recaptchaToken);
      commit('replaceDedicatedEndpoint', { id, newEndpoint: endpoint.data, dedicatedNodeId });
    } catch (e) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('endpoints_roll-endpoint'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
};

const DedicatedNodes: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default DedicatedNodes;

