import { getAllPackages, getExtendedPackages } from '@/api/index';
import { PackagesActions, PackagesGetters, PackagesMutations, PackagesState } from '@/types/packages/store';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/types/store';
import { PricingPackage, ExtendedPricingPackage } from '@/types/packages/packages';
import { useNotification } from '@/composables/useNotification';

export type State = PackagesState;
export type Mutations = PackagesMutations;
export type Getters = PackagesGetters;
export type Actions = PackagesActions;

const state: () => State = () => ({
  availablePackagesRequests: [],
  availablePackagesUnlimited: [],
  extendedPackages: [],
});

const getters: GetterTree<State, RootState> & Getters = {};

const mutations: MutationTree<State> & Mutations = {
  setAvailablePackagesRequests(state, packages) {
    state.availablePackagesRequests = packages;
  },
  setAvailablePackagesUnlimited(state, packages) {
    state.availablePackagesUnlimited = packages;
  },
  setExtendedPackages(state, packages) {
    state.extendedPackages = packages;
  },
};

const actions: ActionTree<State, RootState> & Actions = {
  async setAvailablePackages({ commit }) {
    try {
      const { data } = await getAllPackages();
      const packagesRequests = (data.plans as PricingPackage[])
        .filter((pack) => pack.name.includes('Start'))
        .map((pack) => {
          return {
            _id: pack._id,
            name: pack.name,
            rps: pack.rps,
            price: pack.price,
            requests: pack.requests,
            sale: {
              '1': pack.sale[1],
              '3': pack.sale[3],
              '6': pack.sale[6],
              '9': pack.sale[9],
              '12': pack.sale[12],
            },
          };
        });
      commit('setAvailablePackagesRequests', packagesRequests);

      const packagesUnlimited = (data.plans as PricingPackage[])
        .filter((pack) => pack.name.includes('Unlimited'))
        .map((pack) => {
          return {
            _id: pack._id,
            name: pack.name,
            rps: pack.rps,
            price: pack.price,
            requests: pack.requests,
            sale: {
              '1': pack.sale[1],
              '3': pack.sale[3],
              '6': pack.sale[6],
              '9': pack.sale[9],
              '12': pack.sale[12],
            },
          };
        });
      commit('setAvailablePackagesUnlimited', packagesUnlimited);
    } catch (error) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('general_get-pricing'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
  async setExtendedPackages({ commit }) {
    try {
      const { data } = await getExtendedPackages();
      const extendedPackages = data as ExtendedPricingPackage[];
      commit('setExtendedPackages', extendedPackages);
    } catch (error) {
      const { errorData } = useNotification();
      commit('setNotificationMessage', { ...errorData('general_get-increase-pricing'), type: 'error' });
      commit('setNotificationMessageDisplay', true);
    }
  },
};

const packages: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
};

export default packages;
