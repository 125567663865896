import { createStore, useStore as VuexStore } from 'vuex';
import {
  general,
  user,
  eventTracker,
  dedicatedNodeConfigurator,
  profile,
  dedicatedNodes,
  auth,
  sharedNodes,
  statistics,
  packages,
  teams,
  onboarding
} from '@/store/modules';
import { getGift } from '@/api/index';
import { StoreType } from '@/types/store';

// @ts-ignore
const store: StoreType = createStore({
  modules: {
    general,
    user,
    eventTracker,
    dedicatedNodeConfigurator,
    profile,
    dedicatedNodes,
    auth,
    sharedNodes,
    statistics,
    packages,
    teams,
    onboarding
  },
  // @ts-ignore
  state() {
    return {
      accessToken: '',
      buyFromWebsite: {
        active: false,
        packageKey: '',
      },
      userGift: '',
      isNeededToShowDedicatedNodeConfigurator: false,
    };
  },

  mutations: {
    setBuyFromWebsite(state, data) {
      state.buyFromWebsite = data;
    },
    setUserGift(state, data) {
      state.userGift = data;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setIsNeedToShowDedicatedNodeConfigurator(state, show) {
      state.isNeededToShowDedicatedNodeConfigurator = show;
    },
  },
  actions: {
    async checkIfUserHasGift({ commit }) {
      try {
        const response = await getGift();
        if (!response.data.gift) commit('setCNYModalDisplay', true);
        else commit('setUserGift', response.data.gift.split('/')[1]);
      } catch (e) {
        console.error('get user gift error: ', e);
      }
    },
  },
});


export function useStore(): StoreType {
  return VuexStore() as StoreType;
}

export default store;
