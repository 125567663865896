import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';
import { createI18n } from 'vue-i18n';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import * as amplitude from '@amplitude/analytics-browser';
// @ts-ignore
import mixpanel from 'mixpanel-browser';
// @ts-ignore
import App from './App.vue';
// @ts-ignore
import router from './router';
import store from './store';
import '@getblockui/core/styles';
import '@getblockui/common/styles';
import './assets/styles/tailwind.css';
import './assets/styles/fonts.css';
import './assets/styles/index.scss';
import en from './locales/en.json';
import cn from './locales/cn.json';

const app = createApp(App);

export const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: en,
    cn: cn,
  },
});
amplitude.init(process.env.VUE_APP_AMPLITUDE_API_KEY as string, {
  defaultTracking: false,
});
mixpanel.init(process.env.VUE_APP_MIXPANEL_API_KEY)

Sentry.init({
  app,
  dsn: 'https://ac1d84dc8a2443f59bd2201b6ff30fcb@o590175.ingest.sentry.io/5991818',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['account.getblock.io', 'account.getblock.dev', /^\//],
    }),
    new CaptureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  tracesSampleRate: 1.0,
});

app.directive('click-outside', {
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event: Event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});
app.use(router);
app.use(store);
app.use(i18n);
app.use(
  createGtm({
    id: 'GTM-T7WF552',
    enabled: true,
  }),
);
app.config.errorHandler = function (err, vm, info) {
  console.log(err, vm, info);
};
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY as string, loaderOptions: { autoHideBadge: true } });
app.mount('#app');
